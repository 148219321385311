export const sectionsMetadata = {
	aboutPage: {
		meta: {
			title: 'ZAMBEZI | ABOUT',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/about/',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	contactPage: {
		meta: {
			title: 'ZAMBEZI | CONTACT',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/contact/',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	workPage: {
		meta: {
			title: 'ZAMBEZI | WORK',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/work/',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	homePage: {
		meta: {
			title: 'ZAMBEZI | TAKE BIGGER BITES',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	newsPage: {
		meta: {
			title: 'ZAMBEZI | NEWS',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/news',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	newsItemPage: {
		meta: {
			title: 'ZAMBEZI | NEWS',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/news'
		}
	},
	newsPageWork: {
		meta: {
			title: 'ZAMBEZI | NEWS | WORK',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/news/work',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	newsPagePeople: {
		meta: {
			title: 'ZAMBEZI | NEWS | PEOPLE',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/news/people',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	newsPagePerspectives: {
		meta: {
			title: 'ZAMBEZI | NEWS | PERSPECTIVES',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/news/perspectives',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	newsPageAwards: {
		meta: {
			title: 'ZAMBEZI | NEWS | AWARDS',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/news/awards',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	newsPageBites: {
		meta: {
			title: 'ZAMBEZI | NEWS | BITES',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/news/bites',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	careersPage: {
		meta: {
			title: 'ZAMBEZI | CAREERS',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/careers',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	capabilitiesPage: {
		meta: {
			title: 'ZAMBEZI | CAPABILITIES',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/capabilities',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	PrivacyPolicyPage: {
		meta: {
			title: 'ZAMBEZI | PRIVACY POLICY',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/privacy-policy',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	TermsOfUsePage: {
		meta: {
			title: 'ZAMBEZI | TERMS OF USE',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/terms-of-use',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	}
};
